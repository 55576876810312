import React, { useState } from "react";
import './/App.css';
import Spinner from 'react-bootstrap/Spinner';
import axios from "axios";





function App() {


  const [file, setValue] = useState('');
  const [google, setResults] = useState('');
  const [yandex, setData] = useState('');
  const [show, setState] = useState(false);
  const [spinner, setSpinner] = useState(false);



  const server_URL = process.env.REACT_APP_API_KEY
  const server_URL2 = process.env.REACT_APP_API_KEY2


  const onClick = async (e) => {

    e.preventDefault();
    setSpinner(true)
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(server_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      //console.log(response.data.response.search_metadata.google_lens_url);

      setResults(response.data.response.search_metadata.google_lens_url);

      const result = await axios.post(server_URL2, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      //console.log(result.data.result.search_metadata.yandex_images_url);
      setData(result.data.result.search_metadata.yandex_images_url);

      setState(true);

    } catch (err) {
      alert(err);

    }
    finally {
      setSpinner(false); // Hide spinner after API call
    }
  }


  return (
    <div>


      <div class="header">
        <h1>Dhoond-La Search</h1>
        <p>
          How Do I Conduct A Dhoond-LA Search?
          <br />


          Uploading an image Search by Image<br />
          Hit the Choose file button and select your desired image.<br />
          Press the “Upload” button.
          Explore results from 1.3 Billion searches<br />
          Dhoondla just got upgrated became faster and more accurate .

          <p>
            Guidelines <br />
            crop written part on the image for better results.
          </p>
          <p>we know the ads are annoying but to keep this website running and to upgrade it<br /> we need your support
            we appreciate your patience</p>

        </p>

        <div class="ad" style={{ width: "728px", height: "90px" }}></div>
      </div>
      <div class="main-content">
        <div class="content">
          < form class="container" >
            <div class="cloud-icon">
              <img src="https://img.icons8.com/?size=100&id=y9OsIxmZTFWI&format=png&color=000000" alt="Cloud Icon" />
            </div>
            <h1>Upload image to search</h1>

            <input class='form-control' type="file" name='file' onChange={(e) => setValue(e.target.files[0])} />

            <button class="upload-button" onClick={onClick}>Upload</button>

            <h2>Find similar images in different search engines.</h2>
            {show ? (<div class="search-engines">
              <div class="search-engine">
                <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google Logo" />
                <a class="show-matches" href={google} target='_blank'>Show Matches </a>
              </div>
              <div class="search-engine">
                <img src="https://pngimg.com/uploads/yandex/yandex_PNG13.png" alt="Yandex Logo" />
                <a class="show-matches" href={yandex} target='_blank'>Show Matches </a>
              </div>
            </div>) : null}
            {spinner ? (<div class='d-flex justify-content-center m-3'><Spinner animation="border" role="status" variant="light" >

            </Spinner> <p> Loading.. Please Wait </p></div>) : null}
          </form>

          <section class='container'>
            <h4> Dhoond -la Search </h4>

            <p>Your first ever image friendly search platform

              Which performs 1 billions searches within few seconds,
              just upload your image and dhoondla will perform a complete analyis of the image weather its a screen short of a movie scene or an image of an object or person
              dhoondla will give you complete details of that image and will also find you similer images and its its original source</p>



          </section>

          <section class='container'>
            <h4>Guidelines</h4>
            <li>Make sure screenshot has a clear view of the Person/Object </li>
            <li>Make sure screenshot has a clear view of the Person's face for better results</li>
            <li>Crop the image to highlight the person/object for better results </li>
            <li>If an image has a colorfull background crop it in a way it highlights the person/object you are looking for  </li>
            <li>Make Sure you are uploading that are relevant for internet search </li>


          </section>

          <section class='container'>
            <h4>According to US</h4>
            <p>
              What you'll find in checking Dhoond-LA Search?
              When you search for or with a picture, you may get results containing:

              Similar images
              The list of websites that contain these images
              Other sizes (dimensions) of the picture you searched with
              What do they say?





            </p>

          </section>
          <section class='container'>
            <h4>Why to search by image?</h4>
            <p>
              The Dhoond-la Search (tool) is built for everyone if you want to know, How do you do an image search on the iPhone?
              from all walks of life. Whether you want to use it for personal, professional,
              or commercial purposes, you are welcome to do so. We only ask that you use it for legitimate reasons only. Below is a list of our most popular user group.

              <li>Mobile users wanting to use Dhoond-la Search from their smartphones or iPhones.</li>
              <li>SEO professionals looking for websites using their images to gain image credit and links.</li>
              <li>Website owners and publishers looking for quality images to grace their content.</li>
              <li>photographers and artists looking for websites that use their work without permission.</li>
              <li>Users who want to find the original source of an image.</li>
              <li>People checking if their personal images have been used online.</li>
              <li> Quite literally anyone looking for images online.</li>


            </p>

          </section>

          <section class='container'>
            <h4> ORIGINAL SOURCES, AND RELEVANT INFORMATION</h4>
            <p>Dhoond-la  image search is a type of online search where,
              instead of typing in a text-based keyword, the user uploads an image to find visually similar images and relevant details about the image. It can also be called “search by image”


              Once you've provided the image, Dhoond-la Search tool will scan the internet for results matching it.
              Hence, search using an image allows you to quickly access relative information about a given image,
              including information about the objects and people in it along with their corresponding metadata.</p>
          </section>
          <section class='container'>
            <h4>Content Safety Guarantee</h4>
            <p>

              Our platform requires that you upload an image  to be able to get the results you want.
              We will like to state here that after you have provided the image for search purposes, we do NOT store or share your images. Which means that your images are completely secure? We respect your privacy and will never violate it.


            </p>

          </section>
          <section class='container'>



            <p>With billions upon billions of images available around the web,
              it could be hard to sort through all of those to find exactly the images you are looking for,
              their sources (image match), and their accompanying information. It can also prove a challenge finding alternate sizes and cropped thumbnails of images that are already in your possession or even finding other websites using the same image. However, this is where <a href="#">Dhoond-la Search </a> comes to the rescue!</p>

            <table class="table-container">
              <tr>
                <td>🔑</td>
                <td>Location of images:</td>
                <td>Exact</td>
              </tr>
              <tr>
                <td>🔒</td>
                <td>Privacy :</td>
                <td>100% Secure</td>
              </tr>
              <tr>
                <td>🌐</td>
                <td>Search Engine:</td>
                <td>Multiple+</td>
              </tr>
              <tr>
                <td>🎯</td>
                <td>Exact match Results:</td>
                <td>🎯</td>
              </tr>
              <tr>
                <td>🔍</td>
                <td>Searches :</td>
                <td>Unlimited images</td>
              </tr>
            </table>

          </section>

          <div class="ad" style={{ width: "300px", height: "250px" }}></div>
          <p></p>

        </div>
        {/* <div class="sidebar">
          <div class="ad" style={{ width: "300px", height: "600px;" }}></div>

        </div> */}
      </div>
      <div class="footer">
        <div class="ad" style={{ width: "728px", height: "90px" }}></div>
        {/* <form>
          <input type='text'  placeholder='yourname'/>
          <input textarea='text'  placeholder='massage'/>
          <button class="upload-button"  > submit </button>
    
        </form> */}
        <div class="social-links">
        <p>for guidence contact us at<br />
        </p>
          
          <a href="https://www.facebook.com/profile.php?id=100008961133347&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-facebook"></i> Facebook
          </a> |
         

        </div>
        
        <p>
        dhoondle@aol.com</p>
        <p>2024 Dhoond-la Search. All Rights Reserved</p>

      </div>












    </div>










  )
}

export default App;